var googleTranslateScript = document.createElement('script');
googleTranslateScript.type = 'text/javascript';
googleTranslateScript.async = true;
googleTranslateScript.src = 'https://translate.google.com/translate_a/element.js';
(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(googleTranslateScript);

function googleTranslateElementInit() {
    new google.translate.TranslateElement({
      pageLanguage: 'en',
      includedLanguages: 'en,fr,zh-CN,zh-TW',
      layout: google.translate.TranslateElement.InlineLayout.SIMPLE
    }, 'google_translate_element');
  }


// function googleTranslateElementInit() {
//   //alert();
//     new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element');
//     try {
//         if (document.getElementById('google_translate_element') !== null) {
//             if ($('#google_translate_element').is(':empty')) {
//                 let googleInstance = google;
//                 if (googleInstance && googleInstance.translate && googleInstance.translate.TranslateElement != null) {
//                     googleInstance.translate.TranslateElement({
//                         pageLanguage: 'en',
//                         includedLanguages: 'es,fr,id,pt,tr,lo,vi,th',
//                         layout: googleInstance.translate.TranslateElement.InlineLayout.SIMPLE
                        
//                     }, 'google_translate_element');
//                 }

//             }
//         }

//     } catch (error) {
//         //console.log(error, "google translator")
//     }
// }